<template>
  <div className="animated">
    <b-card class="currency-control" style="padding: unset;">
      <b-card-header>
        Currency Control
      </b-card-header>
      <b-card-body class="p-4">
        <currency-control-table></currency-control-table>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import CurrencyControlTable from "./Table";

export default {
  name: "currency-control",
  components: {
    CurrencyControlTable
  }
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.currency-control .card-body {
  padding: unset;
}
</style>
